import { render, staticRenderFns } from "./ArrangeSetting.vue?vue&type=template&id=e479bcb2"
import script from "./ArrangeSetting.vue?vue&type=script&lang=js"
export * from "./ArrangeSetting.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/.pnpm/vue-loader@15.11.1_@vue+compiler-sfc@3.5.6_css-loader@6.11.0_webpack@5.94.0__ejs@3.1.10_lodas_fy3ylwl4gswzn7dyxdhzpz2lxe/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports